module directives {
    export module integration {
        interface IExportVesselScheduleDirective extends ng.IScope {
            exportVesselScheduleData: interfaces.logistics.ExportVesselScheduleDashboardView[];
            filteredExportVesselScheduleData: interfaces.logistics.ExportVesselScheduleDashboardView[];
            exportVesselScheduleSummaryData: interfaces.logistics.ExportVesselScheduleDashboardViewSummary[];
            isLoading: boolean;
            PortTerminals: Array<interfaces.applicationcore.IDropdownModel>;
            LoadExportVesselScheduleData();
            getTerminalUpdate();
            loadPortTerminals();
            refreshSummaryView();
            navigateToVesselSchedule(vshId:number);
            navigateToConsignment(conId:number);
            getVesselScheduleDetailById(vshId:number): interfaces.logistics.ExportVesselScheduleDashboardView[];
            portTerminalId: number;
            searchText: string;
            terminalInfo: interfaces.logistics.TerminalInfo;
            searchChanged();
            change(model: interfaces.applicationcore.IDropdownModel): void;
            replaceAll(str : string, find: string, replace: string):string;
            downloadToExcel();

            //HTML5 Drag and Drop Methods

            dragStart($event: any,vesselSchedule:interfaces.logistics.ExportVesselScheduleDashboardView);
            dragDrop($event: any,vesselScheduleSummary:interfaces.logistics.ExportVesselScheduleDashboardViewSummary);
            dragEnter($event:any);
            dragOver($event:any);

        }

        export class exportVesselScheduleDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/logistics/exportVesselScheduleView.html';
            scope = {
            }

            public exportVesselScheduleData: interfaces.logistics.ExportVesselScheduleDashboardView[];
            public exportVesselScheduleSummaryData: interfaces.logistics.ExportVesselScheduleDashboardViewSummary[];

            constructor(
                private generalService: interfaces.applicationcore.IGeneralService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private vesselScheduleService: interfaces.logistics.IVesselScheduleService,
                private $cookies: ng.cookies.ICookiesService,
            ) {
                
            }

            link = ($scope: IExportVesselScheduleDirective, $element: ng.IAugmentedJQuery, $state) => {


                $scope.loadPortTerminals = () => {
                    $scope.isLoading = true;
                    return this.vesselScheduleService.getGetPortTerminalsForDropDown().query({
                    }, (resultList) => {
                        $scope.PortTerminals = resultList;

                        var lastPortTerminalId = this.$cookies.get('lastPortTerminalId');
                        if (lastPortTerminalId){

                            $scope.portTerminalId = +lastPortTerminalId;
                            $scope.getTerminalUpdate();
                            $scope.LoadExportVesselScheduleData();
                        }

                        $scope.isLoading = false;
                    }).$promise;
                }

                $scope.getTerminalUpdate = () => {
                    return this.vesselScheduleService.GetTerminalUpdate($scope.portTerminalId).query({}, (result) => {
                            $scope.terminalInfo = result;
                    });
                }
   

                $scope.LoadExportVesselScheduleData = () => {
                    $scope.isLoading = true;
                    $scope.searchText = "";
                    return this.vesselScheduleService.getExportVesselScheduleDashboardData($scope.portTerminalId).query({
                    }, (resultList) => {
                         this.$cookies.put('lastPortTerminalId',$scope.portTerminalId.toString());
                        $scope.exportVesselScheduleData = resultList;
                        $scope.filteredExportVesselScheduleData = resultList;
                        $scope.exportVesselScheduleSummaryData =  [];
                        $scope.getTerminalUpdate();
                        $scope.refreshSummaryView();


                        $scope.isLoading = false;
                    }).$promise;
                }

                $scope.refreshSummaryView = () => {
                    $scope.exportVesselScheduleSummaryData = [];
                    angular.forEach( $scope.filteredExportVesselScheduleData.sort((a,b)=> b.vshId-a.vshId), (exportVesselSchedule) => {
                        
                        var exportVesselScheduleSummary =  _.find($scope.exportVesselScheduleSummaryData, (o) => { return o.vshId === exportVesselSchedule.vshId});
                    
                        if (!exportVesselScheduleSummary){
                    
                            exportVesselScheduleSummary = <interfaces.logistics.ExportVesselScheduleDashboardViewSummary>{
                                vshId: exportVesselSchedule.vshId,
                                TerminalId: exportVesselSchedule.TerminalId,
                                VesselId: exportVesselSchedule.VesselId,
                                VisitSequence: exportVesselSchedule.VisitSequence,
                                CallSign: exportVesselSchedule.CallSign,
                                VesselName: exportVesselSchedule.VesselName,
                                StackOpening: exportVesselSchedule.StackOpening,
                                StackClosing: exportVesselSchedule.StackClosing,
                                StackStatus: exportVesselSchedule.StackStatus,
                                EstimateDateOfDeparture: exportVesselSchedule.EstimateDateOfDeparture,
                                ActualDateOfDeparture: exportVesselSchedule.ActualDateOfDeparture,
                                DepartureComments: exportVesselSchedule.DepartureComments,
                                SLA : 0,
                                IsExpanded: false,
                                SLAComments: ''
                            }
                                            
                            $scope.exportVesselScheduleSummaryData.push(exportVesselScheduleSummary);
                    
                        }

                        exportVesselScheduleSummary =  _.find($scope.exportVesselScheduleSummaryData, (o) => { return o.vshId === exportVesselSchedule.vshId});

                        if (exportVesselSchedule.SLA > exportVesselScheduleSummary.SLA){
                            exportVesselScheduleSummary.SLA = exportVesselSchedule.SLA;

                        }
                            
                        if (exportVesselScheduleSummary.SLA > 0){
                            exportVesselScheduleSummary.IsExpanded = true;
                        }

                        if (exportVesselSchedule.ActualDateOfDeparture){
                            exportVesselScheduleSummary.DateOfDeparture = exportVesselSchedule.ActualDateOfDeparture;
                            exportVesselScheduleSummary.DateOfDepartureType = 'Departed';
                        }
                        else
                        {
                            exportVesselScheduleSummary.DateOfDeparture = exportVesselSchedule.EstimateDateOfDeparture;
                            exportVesselScheduleSummary.DateOfDepartureType = 'ETD';
                        }

                        if (exportVesselSchedule.SLAComments !== '')
                        {
                            exportVesselScheduleSummary.SLAComments =  exportVesselScheduleSummary.SLAComments + ' <br/>' + exportVesselSchedule.SLAComments;
                        }

                    });

                   
                }

                $scope.change = (model) => {
                    $scope.LoadExportVesselScheduleData();
                }

                $scope.navigateToVesselSchedule = (vshId:number) => {
                    this.$state.go("auth.VesselSailingSchedule.Update", { vshId: vshId });
                }

                $scope.navigateToConsignment = (conId:number) => {
                    this.$state.go("auth.ConsignmentExports.Update", { conId: conId });
                }


                $scope.getVesselScheduleDetailById =  (vshId:number) => {
                    return $scope.filteredExportVesselScheduleData.filter((o) => {return o.vshId === vshId})
                }


                $scope.searchChanged = () =>{
                    $scope.filteredExportVesselScheduleData = [];
                    for (var i = 0; i < $scope.exportVesselScheduleData.length; i++){    
                       
                        if (JSON.stringify($scope.exportVesselScheduleData[i]).toLocaleLowerCase().includes($scope.replaceAll($scope.searchText.toLocaleLowerCase(),'/','-')))
                        {
                            $scope.filteredExportVesselScheduleData.push($scope.exportVesselScheduleData[i]);
                        }
                    }
                    $scope.refreshSummaryView();
                }

                 $scope.replaceAll = (str, find, replace) => {
                    return str.replace(new RegExp(find, 'g'), replace);
                  }

                $scope.loadPortTerminals();

                $scope.downloadToExcel = () => {
                    this.vesselScheduleService.getExportVesselScheduleDashboardExcel($scope.portTerminalId).then(() => {
                    }, (data) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                    });
                }


                /*  Drag and Drop HTML5 methods */

                $scope.dragStart = ($event: any,vesselSchedule:interfaces.logistics.ExportVesselScheduleDashboardView) =>{
                    $event.dataTransfer.setData('text', vesselSchedule.conId);
                }

                $scope.dragDrop = ($event: any,vesselScheduleSummary:interfaces.logistics.ExportVesselScheduleDashboardViewSummary) => {
                    $event.preventDefault();
                    $scope.isLoading = true;

                    this.vesselScheduleService.allocateConsignmentToVesselSchedule(

                        $event.dataTransfer.getData('text'),
                        vesselScheduleSummary.vshId

                    ).save( (result) => {

                        this.generalService.displayMessageHandler(result);
                        $scope.LoadExportVesselScheduleData();

                        $scope.isLoading = false;
                    }); 
                }

                $scope.dragEnter = ($event:any) => {
                    $event.preventDefault();
                }

                $scope.dragOver = ($event:any) => {
                    $event.preventDefault();
                }                

                /* End Drag and Drop HTML5 methods */

            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $state, 
                    bsLoadingOverlayService, vesselScheduleService,$cookies) => new exportVesselScheduleDirective(generalService, $state, bsLoadingOverlayService, vesselScheduleService,$cookies);
                directive.$inject = ["generalService", "$state", "bsLoadingOverlayService", "vesselScheduleService",'$cookies'];

                return directive;
            }
        }

        angular.module("app").directive("gtsExportVesselSchedule", exportVesselScheduleDirective.factory());
    }
}